import { useEffect, useState } from 'react';

import { useAccount } from 'wagmi';

import { setgraphDataSelected } from '@/redux/wallet/userSlice';

import { useAppDispatch, useAppSelector } from './redux';

const useWalletHistorical = () => {
  const { walletHistorical, historicalLoading, graphDataInterval } = useAppSelector((state) => state.user);

  const { address } = useAccount();
  const dispatch = useAppDispatch();

  const [formatedData, setFormattedData] = useState<typeof walletHistorical>(null);

  useEffect(() => {
    if (walletHistorical) {
      if (graphDataInterval.val) {
        const filteredbyDate = walletHistorical.filter((value) => value.timestamp > Number(graphDataInterval.val)).sort((a, b) => a.timestamp - b.timestamp);
        setFormattedData(filteredbyDate);
      } else {
        setFormattedData(walletHistorical.slice().sort((a, b) => a.timestamp - b.timestamp));
      }
    }
  }, [walletHistorical, graphDataInterval]);

  const handleSelectInterval = (interval: typeof graphDataInterval) => {
    dispatch(setgraphDataSelected(interval));
  };

  useEffect(() => {
    // if (address) {
    //   dispatch(
    //     fetchWalletHistorical({
    //       walletAddress: address,
    //       granularity: graphDataInterval.granularity,
    //       from_timestamp: graphDataInterval.from,
    //       to_timestamp: graphDataInterval.to,
    //       marketplace_ids: 'opensea',
    //     })
    //   );
    // }
  }, [address, graphDataInterval]);

  return { historicalLoading, formatedData, handleSelectInterval, selectedInterval: graphDataInterval };
};

export default useWalletHistorical;
