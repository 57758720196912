import NFTnews from '../assets/images/newpic.jpg';

export const latestNews = [
  {
    id: 1,
    img: NFTnews,
    title: 'OpenSea Introduces a New Stolen NFT Policy',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sodales, risus at maximus rutrum eros augue scelerisque felis.Lorem ipsum dolor sit amet.',
  },
  {
    id: 2,
    img: NFTnews,
    title: 'OpenSea Introduces a New Stolen NFT Policy',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sodales, risus at maximus rutrum eros augue scelerisque felis.Lorem ipsum dolor sit amet.',
  },
  {
    id: 3,
    img: NFTnews,
    title: 'OpenSea Introduces a New Stolen NFT Policy',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sodales, risus at maximus rutrum eros augue scelerisque felis.Lorem ipsum dolor sit amet.',
  },
  {
    id: 4,
    img: NFTnews,
    title: 'OpenSea Introduces a New Stolen NFT Policy',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sodales, risus at maximus rutrum eros augue scelerisque felis.Lorem ipsum dolor sit amet.',
  },
];

export const chartMockData = [
  {
    usd: 0,
    timestamp: new Date().getTime(),
  },
  {
    usd: 14,
    timestamp: new Date().getTime(),
  },
  {
    usd: 0,
    timestamp: new Date().getTime(),
  },
  {
    usd: 13,
    timestamp: new Date().getTime(),
  },
  {
    usd: 6,
    timestamp: new Date().getTime(),
  },
  {
    usd: 9,
    timestamp: new Date().getTime(),
  },
];

export const collectionData = [
  {
    id: 0,
    name: 'Name',
    volume: 500,
    avgSize: 30,
    count: 80,
    avgTerm: 30,
    floor: 15,
  },
  {
    id: 1,
    name: 'Name',
    volume: 500,
    avgSize: 30,
    count: 80,
    avgTerm: 30,
    floor: 12,
  },
  {
    id: 2,
    name: 'Name',
    volume: 500,
    avgSize: 30,
    count: 80,
    avgTerm: 30,
    floor: 18,
  },
];

export const openLendLoans = [
  {
    id: '0',
    lender: 'JACK.ZETH',
    principal: 20,
    duration: 120,
    apr: 34.45,
    payoff: 25,
    expires: '06.05.2023',
    status: 'accept',
  },
  {
    id: '1',
    lender: 'JACK.ZETH',
    principal: 20,
    duration: 120,
    apr: 34.45,
    payoff: 25,
    expires: '06.05.2023',
    status: 'accept',
  },
  {
    id: '2',
    lender: 'JACK.ZETH',
    principal: 20,
    duration: 120,
    apr: 34.45,
    payoff: 25,
    expires: '06.05.2023',
    status: 'invalid',
  },
  {
    id: '3',
    lender: 'JACK.ZETH',
    principal: 20,
    duration: 120,
    apr: 34.45,
    payoff: 25,
    expires: '06.05.2023',
    status: 'accept',
  },
];
