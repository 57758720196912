import { useQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';

import { GET_WALLET_CRYPTO } from '@/utils/queryKey';
import { getWalletCryptoData } from 'simplehash_api/walletApi';

export const useSpecificWalletCryptos = (wallet_address: `0x${string}` | undefined) => {
  return useQuery({
    queryKey: [GET_WALLET_CRYPTO, wallet_address],
    queryFn: () => getWalletCryptoData({ wallet_addresses: wallet_address! }),
    enabled: !!wallet_address,
  });
};

const useWalletCryptos = () => {
  const { address: wallet_address } = useAccount();
  return useSpecificWalletCryptos(wallet_address);
};

export default useWalletCryptos;
