import { ReactNode } from 'react';

import MinusIcon from '@/assets/icons/minus.svg';
import PlusIcon from '@/assets/icons/plus.svg';

interface FaqAccordionItemProps {
  item: {
    id: number;
    question: string;
    answer: ReactNode;
  };
  isActive: boolean;
  onToggle: () => void;
}

const FaqAccordionItem: React.FC<FaqAccordionItemProps> = ({ item, isActive, onToggle }) => {
  return (
    <li className='lg:p-6 lg:pb-[21px] p-[18px] pb-4 flex-1 rounded-3xl border border-[#3B3B3B]'>
      <button type='button' onClick={onToggle} className='w-full flex items-center justify-between !bg-transparent' aria-expanded={isActive} aria-controls={`faq-answer-${item.id}`}>
        <span className='text-base lg:text-2xl font-medium text-left text-white'>{item.question}</span>
        {isActive ? <MinusIcon className='inline-block ml-2' /> : <PlusIcon className='inline-block ml-2' />}
      </button>
      <div id={`faq-answer-${item.id}`} className={`transition-all duration-300 ease-in-out overflow-hidden ${isActive ? 'max-h-96 opacity-100 mt-6' : 'max-h-0 opacity-0'}`} role='region' aria-labelledby={`faq-question-${item.id}`}>
        <p className='text-base lg:text-lg text-gray-300 font-medium '>{item.answer}</p>
      </div>
    </li>
  );
};

export default FaqAccordionItem;
