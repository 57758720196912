import Link from 'next/link';

const statisticsData = [
  {
    id: 1,
    value: '$2.1B+',
    title: 'Total Loan Market',
    mobileTitle: 'Loan \n Market',
  },
  {
    id: 2,
    value: '$15k',
    title: 'Avg Loan Size',
    mobileTitle: 'Loan \n Size',
  },
  {
    id: 3,
    value: '25%',
    title: 'Avg Monthly APR',
    mobileTitle: 'Monthly \n APR',
  },
];

const Header: React.FC = () => {
  return (
    <header className='px-[25px]'>
      <div className='lg:pt-[248px] pt-[132px] flex flex-col items-center'>
        <hgroup className='lg:max-w-[1028px] max-w-[328px] mx-auto'>
          <h1
            className='[background-image:radial-gradient(circle,_#FFF_77.5%,_rgba(255,_255,_255,_0.60)_100%)]
          lg:[background-image:linear-gradient(124deg,_rgba(255,_255,_255,_0.60)_6%,_#FFF_14%_77.5%,_rgba(255,_255,_255,_0.60)_93%_100%)] text-center font-semibold text-[32px]/9 [background-clip:text] lg:text-7xl/[70px] !text-transparent'
          >
            Borrow Against Your NFTs To Unlock More Crypto
          </h1>

          <p className='mt-6 lg:mt-20 text-center max-w-[228px] mx-auto lg:max-w-full text-base lg:text-2xl font-medium !text-white-dark'>Get alerts on borrowing and lending opportunities.</p>
        </hgroup>

        <Link href='/login' className='mt-[39px] lg:mt-12 w-full ss:w-[228px] h-[42px] flex justify-center items-center rounded-xl !bg-primary text-white text-base/5 font-medium hover:opacity-75 active:scale-95'>
          Launch App
        </Link>
        <div className='flex flex-col lg:mt-[92px] mt-[72px] items-center max-w-[1357px] mx-auto w-full'>
          <div className='max-w-[1131px] w-full'>
            <div className='w-full h-full bg-mobile-home md:bg-hero [mask-image:linear-gradient(#000_0%_80%,transparent_90%)] bg-no-repeat aspect-[1/0.63] sm:aspect-[1.2/0.50] bg-cover bg-primary lg:rounded-t-[41px]'></div>
          </div>
          <ul className='flex items-center justify-between pt-8 lg:pt-[38px] gap-[37px] sm:gap-11 lg:gap-[124px]'>
            {statisticsData.map((item) => {
              return (
                <li key={item.id}>
                  <p className='flex flex-col items-center lg:gap-8 gap-[14px]'>
                    <strong className='text-[22px]/[25px] lg:text-[52px]/[60px] font-semibold text-white'>{item.value}</strong>
                    <span className='text-base lg:text-2xl/9 font-medium text-primary text-center hidden sm:block'>{item.title}</span>
                    <span className='text-base font-normal text-primary text-center whitespace-pre-line sm:hidden'>{item.mobileTitle}</span>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
