import Link from 'next/link';

const JoinFutureSection: React.FC = () => {
  return (
    <section className='md:px-[25px]'>
      <div className='lg:mt-[200px] mt-[148px] max-w-[1343px] mx-auto'>
        <hgroup className='max-w-[788px] mx-auto flex items-center flex-col gap-8 px-[25px] md:px-0'>
          <h2 className='max-w-[566px] mx-auto text-center text-[28px]/9 lg:text-[52px]/[60px] font-semibold text-white'>Join The Future of Crypto Backed Loans</h2>
          <p className='text-base lg:text-lg/8 font-normal lg:font-medium !text-white-dark text-center'>At NettyWorth, we empower users to unlock the full potential of their digital assets. Our AI-driven protocol provides the tools you need to borrow, lend, and succeed in the evolving world of DeFi.</p>
          <Link href='/login' className='w-full md:w-[260px] h-14 rounded-[20px] flex justify-center items-center !bg-primary font-medium text-base/5 lg:text-[19px]/[20px] !text-white hover:opacity-75 active:scale-95'>
            Get Started
          </Link>
        </hgroup>
        <div className='bg-mobile-home-footer bg-no-repeat w-full min-h-[180px] bg-cover sm:hidden mt-[50px]'></div>
        <img src='/all-platform.png' alt='Netty Worth is working on all platforms' className='w-full mt-16 object-contain hidden sm:flex' />
      </div>
    </section>
  );
};

export default JoinFutureSection;
