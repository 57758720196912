import moment from 'moment';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { TwitterShareButton } from 'react-share';
import { Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, YAxis } from 'recharts';

import { postTwitter } from '@/api/api';
import ChevronIcon from '@/assets/icons/icon-chevron-down.svg';
import ExplandIcon from '@/assets/icons/icon-expland.svg';
import useAvailableChain from '@/hooks/query/useAvaliableChain';
import useWalletCryptos from '@/hooks/query/useWalletCrypto';
import useWalletValuation from '@/hooks/query/useWalletValuation';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import useWalletHistorical from '@/hooks/useWalletHistorical';
import { selectActiveChain, setSelectedNettyWorth } from '@/redux/wallet/userSlice';
import { intervals } from '@/utils/constant';
import { USDDollarFormatter } from '@/utils/formatter';
import { cn } from '@/utils/helper';
import 'rc-dropdown/assets/index.css';

import { chartMockData } from '../../../mock_database/mockdata';

const CustomTooltip = ({ active, payload }: TooltipProps<number, number>) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <p className='label'>{`${payload[0].payload.granularity === 'hourly' ? moment(payload[0].payload.timestamp).format('DD MMM h:mm A') : moment(payload[0].payload.timestamp).format('DD/MM/YY')} : ${USDDollarFormatter(payload[0].value ?? 0)}`}</p>
      </div>
    );
  }

  return null;
};

const PortfolioStats: React.FC = () => {
  const { walletHistorical, selectedNettyWorth, activeChain } = useAppSelector((state) => state.user);
  const { formatedData, handleSelectInterval, historicalLoading, selectedInterval } = useWalletHistorical();
  const { data: walletValuation, isLoading: walletValuationLoading } = useWalletValuation();
  const { data: walletCrypto, isLoading: walletCryptoLoading } = useWalletCryptos();
  const nettyWorth = walletCrypto?.walletCryptoBalance && walletValuation ? walletCrypto.walletCryptoBalance + walletValuation : 0;
  const dispatch = useAppDispatch();
  const { data: chains } = useAvailableChain();

  const onSelectChain = (chain: string) => {
    dispatch(selectActiveChain(chain));
  };

  return (
    <div className='lg:px-[18px] pt-[19px] pb-5 lg:pb-[25px] border-shade-blue-grey dark:!bg-jet-black lg:dark:!bg-inherit lg:border-b-0'>
      <Dropdown
        animation='slide-up'
        overlay={
          <Menu className='h-[150px] overflow-y-auto'>
            {chains?.map((chain) => {
              return (
                <MenuItem
                  key={chain.chain}
                  className='text-input bg-white flex justify-center items-center text-base hover:bg-light-gray !py-5'
                  onClick={() => {
                    onSelectChain(chain.chain);
                  }}
                >
                  {chain.chain}
                </MenuItem>
              );
            })}
          </Menu>
        }
        trigger={['click']}
      >
        <div className='hidden max-h-[50px] lg:absolute -top-[58px] right-[18px] z-10  max-w-[340px] py-4 px-[14px] lg:px-[27px] bg-light-blue-grey pl-5 pr-[17px] lg:flex justify-between gap-[17px] rounded items-center dark:border'>
          <div className='flex items-center gap-2'>
            <p className='text-dark-blue text-[14px]'>Chain</p>
            <p className='text-primary text-[14px] font-normal capitalize'>{activeChain}</p>
          </div>
          <ChevronIcon className='fill-white' />
        </div>
      </Dropdown>
      <div className='bg-white rounded-lg dark:*:!bg-jet-black'>
        <div className='px-5 pb-[36px] pt-[38px] lg:p-[29px_36px_32px_26px] flex justify-between items-center dark:rounded-t'>
          <div className='flex flex-col dark:*:!bg-jet-black gap-[2px]'>
            <p className='text-dark-blue text-[22px]/none font-semibold'>
              Netty<span className='text-primary text-[22px] font-semibold'>Worth</span>
            </p>
            {!walletCryptoLoading && !walletValuationLoading ? <p className='text-[32px] font-bold text-dark-blue'>{USDDollarFormatter(nettyWorth)}</p> : <div className='loader'></div>}
          </div>
          <TwitterShareButton
            resetButtonStyle={false}
            url={process.env.NEXT_PUBLIC_SITE_URL}
            onClick={postTwitter}
            className='bg-jet-black dark:!bg-jet-black-100 flex justify-between items-center gap-2 cursor-pointer rounded-[59px] px-4 py-[6px]'
            title={`I just calculated my #NFT portfolio's value on @nettyworthapp and it's ${USDDollarFormatter(nettyWorth)}. Are you curious to find out how much your NFTs are worth? Head over to:`}
          >
            <p className='text-base text-white font-bold'>Share</p>
            <ExplandIcon className='w-4 [&_path]:!fill-white' />
          </TwitterShareButton>
        </div>
        <div className='border-t relative'>
          <div className='w-full h-[238px] lg:h-[300px] dark:*:!bg-jet-black'>
            <ResponsiveContainer height='100%' width='100%'>
              <LineChart margin={{ top: 50, bottom: 40 }} width={278} height={300} data={formatedData?.length && !historicalLoading ? formatedData : chartMockData}>
                {!historicalLoading && walletHistorical?.length ? <Tooltip content={<CustomTooltip />} /> : null}
                <Line dot={false} type='monotone' dataKey='usd' stroke='#FC74FE' strokeWidth={3} />
                <YAxis type='number' domain={['auto', 'auto']} hide />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className='border-t px-5 pt-5 lg:p-[20px_36px_29px_26px] flex justify-between items-center dark:*:!bg-jet-black dark:rounded-b'>
          <div className='flex items-center gap-2 '>
            {intervals.map((item) => {
              return (
                <button key={item.title} className={cn('py-[6px] sm:py-2 text-sm font-semibold px-2 rounded dark-red', selectedInterval.title === item.title ? '!bg-primary text-white dark:!text-jet-black' : 'bg-shade-blue-grey dark:!bg-midnight-gray !text-grey-lavender')} onClick={() => handleSelectInterval(item)}>
                  {item.title}
                </button>
              );
            })}
          </div>
          <div className='flex items-center rounded-[4px]'>
            <button className={cn(selectedNettyWorth === 'FLOOR' ? '!bg-primary [&_span]:text-white' : 'bg-shade-blue-grey [&_span]:!text-grey-lavender', 'rounded-l-[4px] py-1 px-4')} onClick={() => dispatch(setSelectedNettyWorth('FLOOR'))}>
              <span className='text-xs/none font-semibold sm:font-bold'>FLOOR</span>
            </button>
            <button className={cn(selectedNettyWorth === 'TRAIT' ? '!bg-primary [&_span]:text-white' : 'bg-shade-blue-grey [&_span]:!text-grey-lavender', 'rounded-r-[4px] py-1 px-4')} onClick={() => dispatch(setSelectedNettyWorth('TRAIT'))}>
              <span className='text-xs/none font-semibold sm:font-bold'>TRAIT</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioStats;
