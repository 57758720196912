import type { ClassValue } from 'clsx';
import { usePathname } from 'next/navigation';

import CloseIcon from '@/assets/icons/icon-close.svg';
import WalletIcon from '@/assets/icons/icon-wallet.svg';
import { useAppDispatch } from '@/hooks/redux';
import { openWalletModal } from '@/redux/wallet/userSlice';
import { cn } from '@/utils/helper';

interface IConnectWallet {
  isModalshow?: () => void;
  children: React.ReactNode;
  className?: ClassValue;
}

const ConnectWallet: React.FC<IConnectWallet> = ({ isModalshow, children, className }) => {
  const appDispatch = useAppDispatch();
  const pathname = usePathname();

  return (
    <div className={cn(isModalshow ? 'relative' : '', className)}>
      {isModalshow && (
        <button
          className={cn('absolute -top-[50px] right-0 bg-primary p-[14px] rounded')}
          onClick={() => {
            isModalshow();
            appDispatch(openWalletModal(false));
          }}
        >
          <CloseIcon fill='#FFFFFF' />
        </button>
      )}
      <div className='flex flex-wrap px-10 gap-10 justify-center lg:justify-between lg:gap-5 lg:pl-[77px] lg:h-auto lg:pr-[41px] items-center bg-gradient-to-r from-primary to-[#9A42F3] dark:!from-transparent dark:before:content-none  dark:!bg-jet-black dark:border rounded pt-[76px] pb-[54px] relative before:content-[""] before:absolute before:w-[90%] before:h-[95%] before:bg-banner-rows'>
        <div className='flex flex-col gap-[10px]  dark:!bg-transparent'>
          <div className='flex flex-col lg:flex-row items-center gap-[13px] dark:!bg-transparent'>
            <div className='p-4 border-2 border-white rounded flex justify-center items-center'>
              <WalletIcon className='fill-white scale-[1.70]' />
            </div>
            <h2 className='text-white text-xl font-semibold lg:text-4xl lg:font-bold'>Connect your wallet</h2>
          </div>
          <p className='text-white text-base font-normal lg:w-[85%] text-center lg:text-start'>{pathname.includes('/loans/my-loans') ? 'Connect your wallet to view your loans' : 'Connect your wallet to start a Loan Request'}</p>
        </div>
        {children}
      </div>
    </div>
  );
};

export { ConnectWallet };
