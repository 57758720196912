import { useConnect } from 'wagmi';

const usePopularWallets = () => {
  const { connectors } = useConnect();

  const popularConnectorName = ['MetaMask', 'Phantom', 'Coinbase Wallet'];
  const connectorsLogo: Record<string, string> = {
    MetaMask: '/metamask.png',
    'Coinbase Wallet': '/coinbase.svg',
    Phantom: '/phantom.png',
  };
  const popularWalletModified = popularConnectorName.map((name) => {
    const foundConnector = connectors.find((conn) => conn.name === name);

    if (foundConnector) {
      return {
        ...foundConnector,
        image: connectorsLogo[foundConnector.name],
      };
    }
    return null;
  });
  const popularWallets = popularWalletModified.filter((wallet): wallet is Exclude<typeof wallet, null> => wallet !== null);
  return popularWallets;
};

export default usePopularWallets;
