import { createConfig, http } from 'wagmi';
import { mainnet, sepolia } from 'wagmi/chains';
import { coinbaseWallet, injected, metaMask, walletConnect } from 'wagmi/connectors';

export const config = createConfig({
  chains: [mainnet, sepolia],

  connectors: [
    coinbaseWallet({
      appName: 'wagmi.sh',
      enableMobileWalletLink: true,
    }),
    metaMask({
      dappMetadata: {
        name: 'Wagmi',
        url: 'https://nettyworth.io/',
      },
    }),
    injected({ target: 'metaMask' }),
    injected({ target: 'coinbaseWallet' }),
    injected({ target: 'phantom', shimDisconnect: true }),
    walletConnect({
      projectId: process.env.NEXT_PUBLIC_WC_PROJECTID,
      showQrModal: true,
      qrModalOptions: {
        themeVariables: {
          '--wcm-z-index': '2000',
        },
      },
    }),
  ],
  ssr: true,
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
  },
});
