import { useQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';

import { GET_AVAILABE_CHAINs } from '@/utils/queryKey';
import { IAvailableChains } from 'simplehash_api/types/simpleHash';

const useAvailableChain = () => {
  const { address } = useAccount();
  return useQuery<IAvailableChains[]>({
    queryKey: [GET_AVAILABE_CHAINs],
    enabled: !!address,
  });
};

export default useAvailableChain;
