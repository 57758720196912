import { useState } from 'react';

import toast from 'react-hot-toast';
import { useConnect } from 'wagmi';

import { useAppDispatch } from '@/hooks/redux';
import usePopularWallets from '@/hooks/useWalletButtons';
import { setShowAnimation } from '@/redux/auth/authSlice';
import { togglePanel } from '@/redux/panel/panelSlice';
import { openWalletModal } from '@/redux/wallet/userSlice';
import { regSw, subscribe } from '@/utils/helper';

const WalletButtons = () => {
  const [loadingConnector, setLoadingConnector] = useState<string | null>(null);

  const { connectors, connectAsync } = useConnect();
  const popularConnectors = usePopularWallets();
  const dispatch = useAppDispatch();

  const useUniversalLinks = false;
  const buildUrl = (path: string, params: URLSearchParams) => `${useUniversalLinks ? 'https://phantom.app/ul/' : 'phantom://'}v1/${path}?${params.toString()}`;

  const connectWalletHandler = async (name: string) => {
    const foundConnector = connectors.find((conn) => conn.name === name);

    if (!foundConnector) {
      toast.error(`Connector ${name} not found`);
      return;
    }

    try {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile || window.ethereum === undefined) {
        if (name === 'MetaMask') {
          window.open(`https://metamask.app.link/dapp/${window.location.origin}`);
        } else if (name === 'Phantom') {
          window.open(`https://phantom.app/ul/browse/${encodeURIComponent(window.location.origin)}?ref=${encodeURIComponent('https://nettyworth.io')}`, '_blank');
        } else if (name === 'Coinbase Wallet') {
          window.open(`https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(window.location.href)}`, '_blank');
        }
      }

      await connectAsync({ connector: foundConnector });
      toast.success('Wallet connected successfully!');

      dispatch(setShowAnimation(true));
      dispatch(togglePanel(null));
      dispatch(openWalletModal(false));

      const activeAccounts = await foundConnector.getAccounts();

      if (!activeAccounts || activeAccounts.length === 0) {
        throw new Error('No active accounts found.');
      }

      const { Mixpanel } = await import('@/api/mixpanel');

      Mixpanel.track('Wallet Connected', {
        wallet_connect_method: foundConnector.name,
        wallet_address: activeAccounts[0],
        wallet_connection_state: true,
      });

      Mixpanel.registerOnce({
        first_wallet_connect_date: new Date(),
        ignore: false,
      });

      Mixpanel.increment('total_wallet_connects');
      Mixpanel.setProperty({ Demography: 'Stuff' });

      try {
        const serviceWorkerReg = await regSw();
        await subscribe(serviceWorkerReg);
      } catch (swError) {
        toast.error('Failed to subscribe to notifications.');
        console.error(swError);
      }
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'An unexpected error occurred.';
      toast.error(errorMessage);
      console.error(e);
    }
  };

  return (
    <div className='flex flex-col gap-[10px] rounded dark:!bg-transparent z-10'>
      {popularConnectors.map((connector) => (
        <button
          key={connector.id}
          className={`flex pl-4 items-center gap-[13px] rounded bg-white min-w-[239px] py-2 w-full dark:border ${loadingConnector === connector.name ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={() => {
            setLoadingConnector(connector.name);
            connectWalletHandler(connector.name).finally(() => setLoadingConnector(null));
          }}
          disabled={loadingConnector === connector.name}
        >
          <img alt={connector.name} src={connector.image || '/placeholder-wallet.png'} className='min-w-[39px] size-[39px] rounded-full' />
          <p className='text-[19px] font-semibold text-jet-black'>{connector.name}</p>
        </button>
      ))}
    </div>
  );
};

export default WalletButtons;
