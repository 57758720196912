import { useQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';

import { GET_WALLET_COLLECTION } from '@/utils/queryKey';
import { getWalletCollection } from 'simplehash_api/walletApi';

const useWalletCollection = (chains: string) => {
  const { address: wallet_address } = useAccount();
  return useQuery({
    queryKey: [GET_WALLET_COLLECTION, chains, wallet_address],
    queryFn: () => getWalletCollection({ chains, wallet_addresses: wallet_address!, spam_score__lte: 90 }),
    enabled: !!wallet_address,
  });
};

export default useWalletCollection;
