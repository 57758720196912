import Image from 'next/image';
import Link from 'next/link';

import { socialMediaIcons } from '@/components/layouts-and-navs/constants';
import { useAppSelector } from '@/hooks/redux';
import { selectIsAuth } from '@/redux/auth/authSlice';

const linksLists = [
  {
    id: 1,
    title: 'Products',
    list: [
      {
        id: 1,
        text: 'Borrow',
        to: '/loans/borrow',
      },
      {
        id: 2,
        text: 'Lend',
        to: '/loans/lend',
      },
      {
        id: 3,
        text: 'Portfolio',
        to: '/portfolio',
      },
    ],
  },
  {
    id: 2,
    title: 'Company',
    list: [
      {
        id: 1,
        text: 'About',
        to: '/about',
      },
      {
        id: 2,
        text: 'Roadmap',
        to: 'https://docs.nettyworth.io/docs/nettyworth-roadmap',
        newTab: true,
      },
      {
        id: 3,
        text: 'Contact Us',
        to: '/contact',
      },
    ],
  },
  {
    id: 3,
    title: 'Resources',
    list: [
      {
        id: 1,
        text: 'FAQ',
        to: 'https://docs.nettyworth.io/docs/nettyworth-faq',
        newTab: true,
      },
      {
        id: 2,
        text: 'Audit',
        to: 'https://docs.nettyworth.io/docs/smart-contract-audit-report',
        newTab: true,
      },
      {
        id: 3,
        text: 'Tokenomics',
        to: 'https://docs.nettyworth.io/docs/tokenomics',
        newTab: true,
      },
    ],
  },
  {
    id: 4,
    title: 'Developers',
    list: [
      {
        id: 1,
        text: 'API/SDK',
        to: '/api-sdk',
      },
      {
        id: 2,
        text: 'GithHub',
        to: 'https://github.com/NettyWorth',
        newTab: true,
      },
      {
        id: 3,
        text: 'README',
        to: 'https://docs.nettyworth.io/docs/welcome',
        newTab: true,
      },
    ],
  },
];

const Footer: React.FC = () => {
  const isAuth = useAppSelector(selectIsAuth);

  return (
    <footer className='pt-12 lg:pt-[76px] pb-6 px-[25px] !bg-black'>
      <div className='max-w-[1112px] w-full m-auto !bg-black'>
        <div className='!bg-black flex flex-col sm:flex-row items-start justify-between gap-[53px] lg:gap-3 flex-wrap'>
          <Link href='/' className='flex-1'>
            <Image src={'/logo-dark.svg'} width={162} height={135} alt='netty worth logo' priority quality={100} className='object-cover min-w-[120px] lg:min-w-[162px] h-auto' />
          </Link>
          <div className='!bg-black grid grid-cols-2 sm:flex flex-grow justify-start lg:justify-between items-start gap-y-12 gap-x-24 sm:gap-12 flex-wrap'>
            {linksLists.map(({ id, title, list }) => (
              <dl key={id} className='flex flex-1 flex-col items-start gap-[18px] lg;gap-[22px]'>
                <dt className='font-medium text-base text-white'>{title}</dt>
                {list.map((item) => (
                  <dd key={item.id}>
                    <Link href={title === 'Products' && !isAuth ? '/login' : item.to} target={item.newTab ? '_blank' : '_self'} className='text-sm/6 font-normal !text-[#9C9DA1] hover:opacity-75 active:scale-95'>
                      {item.text}
                    </Link>
                  </dd>
                ))}
              </dl>
            ))}
          </div>
        </div>

        <ul className='flex items-center gap-[22px] pt-[49px] lg:pt-14 pb-[42px] lg:pb-[49px]'>
          {socialMediaIcons.map((social) => {
            const SocialIcon = social.icon;
            return (
              <li key={social.path}>
                <Link href={social.path} target='_blank' className='hover:opacity-75 active:scale-95'>
                  <SocialIcon className='[&_path]:!fill-[#9C9DA1] size-6' />
                </Link>
              </li>
            );
          })}
        </ul>

        <div className='flex justify-between items-center lg:gap-3 pt-4 lg:pt-6 border-t border-t-grey-coal flex-wrap !bg-black'>
          <p className='!text-[#9C9DA1] '>
            <small className='font-normal text-[10px]/7 lg:text-sm/7'>Copyright ©NettyWorth {new Date().getFullYear()}</small>
          </p>

          <ul className='flex items-center gap-6'>
            <li>
              <Link href='/privacy' target='_blank' className='!text-[#9C9DA1] font-normal text-[10px]/7 lg:text-sm/7 hover:opacity-75 active:scale-95'>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link href='/service' target='_blank' className='!text-[#9C9DA1] font-normal text-[10px]/7 lg:text-sm/7 hidden lg:flex hover:opacity-75 active:scale-95'>
                Terms and conditions
              </Link>
              <Link href='/service' target='_blank' className='!text-[#9C9DA1] font-normal text-[10px]/7 lg:text-sm/7 flex lg:hidden hover:opacity-75 active:scale-95'>
                Terms
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
