import { useQuery } from '@tanstack/react-query';
import { useAccount } from 'wagmi';

import { GET_WALLET_VALUATION } from '@/utils/queryKey';
import { getWalletValuation } from 'simplehash_api/walletApi';

export const useSpecificWalletValuation = (address: `0x${string}` | undefined) => {
  return useQuery({
    queryKey: [GET_WALLET_VALUATION, address],
    queryFn: () => getWalletValuation({ wallet_addresses: address! }),
    select: (data) => data.wallets.at(0)?.usd_value,
    enabled: !!address,
  });
};

const useWalletValuation = () => {
  const { address } = useAccount();
  return useSpecificWalletValuation(address);
};

export default useWalletValuation;
