import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../auth/authSlice';

const initialState = {
  moreModal: false,
  modalcreatePost: false,
  menuModal: false,
  topBanner: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleMoreMenu: (state) => {
      state.moreModal = !state.moreModal;
    },
    toggleMenuModal: (state) => {
      state.menuModal = !state.menuModal;
    },
    actionCreatePost: (state) => {
      state.modalcreatePost = !state.modalcreatePost;
    },
    closeBanner: (state) => {
      state.topBanner = false;
    },
  },
});

export const selectMoreMenu = (state: RootState) => state.modal.moreModal;
export const { toggleMoreMenu, actionCreatePost, closeBanner, toggleMenuModal } = modalSlice.actions;
export default modalSlice.reducer;
