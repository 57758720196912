import React from 'react';

import { useAccount } from 'wagmi';

import { ConnectWallet } from './ConnectWallet';
import { NFTCollection } from './NFTCollection';
import PortfolioStats from './PortfolioStats';
import WalletButtons from './WalletButtons';

const ConnectionProvider: React.FC = () => {
  const { isConnected } = useAccount();

  return (
    <div>
      {isConnected ? (
        <div>
          <PortfolioStats />
          <div className='bg-light-blue-grey lg:bg-transparent pt-[23px] border-t border-t-light-blue-grey lg:border-t-0 lg:pt-0'>
            <NFTCollection />
          </div>
        </div>
      ) : (
        <div className='py-[38px] lg:pb-0 lg:pt-3 px-[18px] lg:bg-chat-cover lg:bg-[-24px_179px]'>
          <ConnectWallet>
            <WalletButtons />
          </ConnectWallet>
        </div>
      )}
    </div>
  );
};

export default ConnectionProvider;
