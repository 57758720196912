import Alerts from './Alerts';
import NettyScore from './NettyScore';

export type FeaturesDataType = {
  id: number;
  advTitle: string;
  advText: string;
  title: string;
  desc: string;
};

const featuresData: FeaturesDataType[] = [
  {
    id: 1,
    advTitle: 'Instant Alerts',
    advText: 'Real Time Updates',
    title: 'Borrowing & Lending Alerts',
    desc: 'Get borrowing and lending alerts, explore assets you can leverage, and uncover lending opportunities to maximize your crypto earnings.',
  },
  {
    id: 2,
    advTitle: 'Netty Score',
    advText: 'Web3 Credit Score',
    title: 'Netty Score',
    desc: "A Web3 credit system provides lenders with insights into borrowers' loan histories, including repayments, and defaults.",
  },
];

const FeaturesSection: React.FC = () => {
  return (
    <section className='px-[25px]'>
      <div className='mt-[148px] lg:mt-[203px] max-w-[1112px] mx-auto'>
        <hgroup className='max-w-[860px]'>
          <h2 className='text-[28px]/9 text-center lg:text-left lg:text-[52px]/[60px] font-semibold text-white'>Our Key Features</h2>
          <p className='text-base lg:text-lg/8 mt-6 lg:mt-8 font-normal lg:font-medium !text-white-dark text-center lg:text-left'>NettyWorth is the first AI-driven lending protocol for NFTs and Real-World Assets, enabling users to borrow cryptocurrency using their digital assets as collateral.</p>
        </hgroup>

        <div className='mt-16 lg:mt-[62px] flex flex-wrap gap-x-3 gap-y-16 justify-around'>
          {featuresData.map((item) => {
            return (
              <article key={item.id} className='max-w-[550px] w-full'>
                <div className='!bg-grey-coal w-full lg:h-[470px] h-[447px] flex flex-col justify-between p-[30px] lg:p-12 pb-0 lg:pb-0 rounded-[40px] overflow-clip relative'>
                  <div className='!bg-grey-coal pb-[10px] lg:pb-[18px]'>
                    <strong className='text-white text-xs/[15px] ss:text-lg/6 font-medium'>{item.advTitle}</strong>
                    <p className='lg:mt-0.5 text-[10px]/[15px] ss:text-sm/6 !text-grey-coal-light font-normal'>{item.advText}</p>
                  </div>
                  {item.advTitle === 'Instant Alerts' ? <Alerts /> : <NettyScore />}
                </div>
                <h3 className='mt-12 lg:mt-[35px] text-primary text-xl lg:text-2xl font-medium'>{item.title}</h3>
                <p className='max-w-[458px] mt-4 text-base lg:text-lg/8 !text-white-dark font-medium'>{item.desc}</p>
              </article>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
