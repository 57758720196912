const logos = [
  {
    id: 1,
    src: '/republic.png',
    alt: 'Republic logo',
  },
  {
    id: 2,
    src: '/acacia.png',
    alt: 'Acacia logo',
  },
  {
    id: 3,
    src: '/trive.png',
    alt: 'Trive logo',
  },
  {
    id: 4,
    src: '/blockchain.png',
    alt: 'Blockchain Founders Fund logo',
  },
];

const VentureBackedArticle: React.FC = () => {
  return (
    <article className='px-[25px] max-w-[1112px] mx-auto mt-12 lg:mt-[184px] px-[25px]'>
      <div className='lg:px-[95px] flex flex-col gap-[30px] lg:pt-[27px] p-7 lg:pb-[89px] !bg-grey-coal rounded-[18px]'>
        <h2 className='text-center text-sm/[10px] lg:text-lg/8 font-semibold text-primary'>Venture Backed</h2>
        <ul className='flex items-center flex-wrap justify-around gap-x-14 gap-y-7'>
          {logos.map((logo) => (
            <li key={logo.id} className='lg:max-w-[174px] max-w-[105px] w-full'>
              <img className='w-full h-auto object-contain' src={logo.src} alt={logo.alt} />
            </li>
          ))}
        </ul>
      </div>
    </article>
  );
};

export default VentureBackedArticle;
