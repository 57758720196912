import { Pie, PieChart } from 'recharts';

const NettyScore: React.FC = () => {
  const data = [
    { name: 'Defaulted Loans', value: 80, fill: '#FC74FE', cornerRadius: 40 },
    { name: 'Repaid Loans', value: 20, fill: '#3d3d3d', cornerRadius: 0 },
  ];

  return (
    <>
      <div className='relative mb-[38px] mx-auto [&_div]:!bg-grey-coal'>
        <div className='lg:block hidden'>
          <PieChart width={242} height={242}>
            <Pie data={data} cx={116} cy={116} startAngle={180} endAngle={540} innerRadius={106} outerRadius={116} paddingAngle={0} strokeWidth={0} dataKey='value' />
          </PieChart>
        </div>

        <div className='lg:hidden block'>
          <PieChart width={242} height={242}>
            <Pie data={data} cx={116} cy={116} startAngle={180} endAngle={540} innerRadius={106} outerRadius={116} paddingAngle={0} strokeWidth={0} dataKey='value' />
          </PieChart>
        </div>

        <div className='absolute inset-0 m-auto w-fit h-fit'>
          <p className='text-center text-[32px]/[37px] lg:text-[52px]/[60px] font-semibold text-white'>80</p>
          <p className='text-white font-medium text-xs/5 lg:text-lg/8 text-center'>Repayment rate</p>
        </div>
      </div>

      <div className='!bg-grey-coal pb-6 lg:pb-12 flex gap-2 items-center justify-around flex-wrap'>
        <button type='button' className='w-1/2 flex-1 min-[344px]:w-full max-[344px]:min-w-full lg:px-[11px] pt-1.5 pb-1 lg:py-1.5 rounded-[14px] text-white !bg-[#ffffff0f] border !border-[#3D3D3D] text-[11px]/5 lg:text-lg/8 font-medium text-center hover:opacity-75 active:scale-95'>
          Repaid Loans: 0
        </button>
        <button type='button' className='w-1/2 flex-1 min-[344px]:w-full max-[344px]:min-w-full lg:px-[11px] pt-1.5 pb-1 lg:py-1.5 rounded-[14px] !text-rose !bg-rose-light border !border-rose text-[11px]/5 lg:text-lg/8 font-medium text-center hover:opacity-75 active:scale-95'>
          Defaulted Loans: 2
        </button>
      </div>
    </>
  );
};

export default NettyScore;
