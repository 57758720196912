import { homeFeedback } from 'constant';

import HomeSwiper from './HomeSwiper';

const Feedback = () => {
  return (
    <section id='feedback' className='mt-[148px] lg:mt-[254px] '>
      <hgroup className='mb-16 lg:mb-[94px]'>
        <h2 className='font-semibold text-[28px]/9 lg:text-[52px]/[60px] text-white text-center'>What our users are saying</h2>
        <p className='mt-6 lg:mt-8 text-center text-base lg:text-lg/8 font-medium max-w-[517px] mx-auto !text-white-dark'>Real feedback from our community on how NettyWorth is transforming their DeFi experience.</p>
      </hgroup>

      <HomeSwiper reverse={true} data={homeFeedback} />
      <HomeSwiper data={homeFeedback} />
    </section>
  );
};

export default Feedback;
